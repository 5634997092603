<template>
	<b-container fluid>
		<b-row>
			<Breadcrumb titulo="Ramais"
						:items="breadcrumb"
						class="breadcrumb" />
		</b-row>
		<b-row class="mx-0 my-1">
			<b-col class="ramais-container p-3">
				<b-row>
					<b-col>
						<input type="text" placeholder="Buscar ramal" v-model="search.query" />
						<select v-model="search.setorSelecionado">
							<option :value="null">Setor</option>
							<option v-for="setor in search.setores" :value="setor.Id">{{setor.Nome}}</option>
						</select>
						<select v-model="search.usuarioSelecionado">
							<option :value="null">Atendente</option>
							<option v-for="usuario in search.usuarios" :value="usuario.Id">{{usuario.Nome}}</option>
						</select>
						<b-button @click="consultarRamais" class="box-cliente rounded-0">Filtrar</b-button>
					</b-col>
					<b-col cols="12" md="auto">
						<b-button @click="cadastrarRamal" class="box-cliente rounded-0">Cadastrar ramal</b-button>
					</b-col>
				</b-row>
				<b-row class="h-100">
					<b-col class="ramais-container-body app-scroll-custom">
						<b-table striped :fields="fields" :items="ramais">
							<template #cell(Inclusao)="data">
								{{moment(data.value).format("DD/MM/YYYY HH:mm")}}
							</template>
							<template #cell(SIPSenha)="data">
								{{data.value?.split("").map(item => "*").join("")}}
							</template>
							<template #cell(actions)="data">
								<i @click="editarRamal(data.item)"
								   title="Editar ramal"
								   class="fas fa-pen mr-3"></i>
								<i @click="excluirRamal(data.item)"
								   title="Excluir ramal"
								   class="fas fa-trash"></i>
							</template>
							<template #cell()="data">
								<i v-if="!data.value?.trim()" class="text-secondary">Null</i>
								{{data.value}}
							</template>
						</b-table>
						<center v-if="ramais && ramais.length == 0 && !search.isSearching && !search.catchError">
							Nenhum ramal disponível para os filtros definidos.
						</center>
						<center v-if="search.isSearching" class="text-cliente">
							<b-spinner class="mr-2" small></b-spinner>
							Carregando...
						</center>
						<center v-if="search.catchError" class="text-danger">
							Houve um erro no carregamento de ramais.
						</center>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
		<b-modal id="gerenciar-ramal"
				 size="md"
				 :title="`${ramal.Id ? 'Editar' : 'Cadatrar novo'} ramal`">
			<div class="data-field">
				<label for="ramal-identificacao">Identificação</label>
				<input type="text"
					   id="ramal-identificacao"
					   v-model="ramal.Identificacao" />
			</div>
			<b-container class="p-0">
				<b-row>
					<b-col v-if="!ramal.UsuarioId">
						<div class="data-field">
							<label for="ramal-setor">Setor</label>
							<select id="ramal-setor" v-model="ramal.SetorId">
								<option :value="null">Nenhum</option>
								<option v-for="setor in search.setores" :value="setor.Id">
									{{setor.Nome}}
								</option>
							</select>
						</div>
					</b-col>
					<b-col>
						<div class="data-field" v-if="!ramal.SetorId">
							<label for="ramal-usuario">Usuário</label>
							<select id="ramal-usuario" v-model="ramal.UsuarioId">
								<option :value="null">Nenhum</option>
								<option v-for="usuario in search.usuarios" :value="usuario.Id">
									{{usuario.Nome}}
								</option>
							</select>
						</div>
					</b-col>
				</b-row>
			</b-container>
			<hr />
			<div class="data-field">
				<label for="ramal-sipservidor">Servidor SIP</label>
				<input type="text" id="ramal-sipservidor" v-model="ramal.SIPServidor" />
			</div>
			<div class="data-field">
				<label for="ramal-sipusuario">Usuário</label>
				<input type="text" id="ramal-sipusuario" v-model="ramal.SIPUsuario" />
			</div>
			<div class="data-field">
				<label for="ramal-sipsenha">Senha</label>
				<input type="password" id="ramal-sipsenha" v-model="ramal.SIPSenha" />
			</div>
			<template #modal-footer="{ cancel }">
				<b-button variant="light"
						  @click="cancel()"
						  class="rounded-0"
						  :disabled="isSaving">Cancelar</b-button>
				<b-button variant="success"
						  @click="salvarRamal"
						  class="rounded-0"
						  :disabled="isSaving || !ramalIsValid">
					<span v-if="isSaving">
						<b-spinner small class="mr-1"></b-spinner>
						Processando...
					</span>
					<span v-else>Confirmar</span>
				</b-button>
			</template>
		</b-modal>
	</b-container>
</template>

<script>
	import axios from "axios";
	import moment from "moment";
	import Breadcrumb from "@/components/breadcrumb/Breadcrumb";

	export default {
		name: "RamaisPage",
		components: {
			Breadcrumb
		},
		data() {
			return {
				breadcrumb: [
					{
						id: "1",
						classe: "fas fa-cogs",
						texto: "Cadastros Básicos",
						link: "/cadastros-basicos",
						cor: "#259cd3"
					},
					{
						id: "2",
						classe: "fas fa-fax",
						texto: "Ramais",
						link: "/ramais",
						cor: "#74e4ff"
					}
				],
				fields: [
					{ key: "Identificacao", label: "Identificação", sortable: true },
					{ key: "SIPServidor", label: "Servidor SIP", sortable: true },
					{ key: "SIPUsuario", label: "Usuário SIP", sortable: true },
					{ key: "SIPSenha", label: "Senha SIP" },
					{ key: "SetorNome", label: "Setor", sortable: true },
					{ key: "UsuarioNome", label: "Usuário", sortable: true },
					{ key: "Inclusao", label: "Data de Inclusão", sortable: true },
					{ key: "actions", label: "Ações" }
				],
				ramais: [],
				ramal: {
					Id: null,
					Identificacao: null,
					SIPServidor: null,
					SIPUsuario: null,
					SIPSenha: null,
					SetorId: null,
					UsuarioId: null
				},
				isSaving: false,
				search: {
					query: null,
					setorSelecionado: null,
					usuarioSelecionado: null,
					setores: [],
					usuarios: [],
					isSearching: false,
					catchError: false
				}
			};
		},
		computed: {
			ramalIsValid() {
				if (!this.ramal) return;
				return this.ramal.Identificacao?.trim() && this.ramal.SIPServidor?.trim() && this.ramal.SIPUsuario?.trim() && this.ramal.SIPSenha?.trim();
			}
		},
		methods: {
			consultarRamais() {
				this.ramais = [];
				this.search.catchError = false;
				this.search.isSearching = true;
				return axios.get(`/api/ramal`, {
					params: {
						Query: this.search.query,
						SetorId: this.search.setorSelecionado,
						UsuarioId: this.search.usuarioSelecionado
					}
				}).then(response => {
					this.search.isSearching = false;
					this.ramais = response.data;
				}).catch(() => {
					this.search.isSearching = false;
					this.search.catchError = true;
				})
			},
			cadastrarRamal() {
				this.ramal.Id = null;
				this.ramal.Identificacao = null;
				this.ramal.SIPServidor = null;
				this.ramal.SIPUsuario = null;
				this.ramal.SIPSenha = null;
				this.ramal.SetorId = null;
				this.ramal.UsuarioId = null;
				this.$bvModal.show("gerenciar-ramal");
			},
			editarRamal(ramal) {
				this.ramal.Id = ramal.Id;
				this.ramal.Identificacao = ramal.Identificacao;
				this.ramal.SIPServidor = ramal.SIPServidor;
				this.ramal.SIPUsuario = ramal.SIPUsuario;
				this.ramal.SIPSenha = ramal.SIPSenha;
				this.ramal.SetorId = ramal.SetorId ?? null;
				this.ramal.UsuarioId = ramal.UsuarioId ?? null;
				this.$bvModal.show("gerenciar-ramal");
			},
			salvarRamal() {
				this.isSaving = true;
				axios[this.ramal.Id ? "put" : "post"](`/api/ramal${this.ramal.Id ? `/${this.ramal.Id}` : ""}`, this.ramal).then(response => {
					this.isSaving = false;
					this.$bvToast.toast("Ramal salvo com sucesso.", {
						title: "Concluído",
						toaster: "b-toaster-top-right",
						solid: true,
						variant: "success",
						appendToast: false,
						autoHideDelay: 3000
					});
					if (!this.ramal.Id) this.ramal.Id = response.data;
					this.consultarRamais();
					this.$bvModal.hide("gerenciar-ramal");
				}).catch(error => {
					this.isSaving = false;
					if (error.response?.status == 409) {
						this.$bvToast.toast([
							this.$createElement("div", {
								domProps: {
									innerHTML: `Houve um conflito entre os dados enviados e as informações salvas em um outro ramal.<br /><hr />Verifique se${this.ramal.UsuarioId ? ' o atendente selecionado já não está vinculado a um outro ramal ou' : ''} se não há um outro ramal com as mesmas configurações de conexão SIP.`
								}
							})
							
						], {
							title: "Conflito de informações",
							toaster: "b-toaster-top-right",
							solid: true,
							variant: "danger",
							appendToast: false,
							autoHideDelay: 7000
						});
						return;
					}
					this.$bvToast.toast(`Não foi possível salvar as informações do ramal no momento.`, {
						title: "Erro",
						toaster: "b-toaster-top-right",
						solid: true,
						variant: "danger",
						appendToast: false,
						autoHideDelay: 3000
					});
				});
			},
			excluirRamal(ramal) {
				this.$bvModal.msgBoxConfirm("O ramal será excluído. Deseja continuar?", {
					title: "Atenção",
					size: "md",
					buttonSize: "sm",
					okVariant: "danger",
					okTitle: "Confirmar",
					cancelTitle: "Cancelar"
				}).then(confirm => {
					if (confirm) axios.delete(`/api/ramal/${ramal.Id}`).then(() => {
						this.$bvToast.toast("Ramal excluído com sucesso.", {
							title: "Concluído",
							toaster: "b-toaster-top-right",
							solid: true,
							variant: "success",
							appendToast: false,
							autoHideDelay: 3000
						});
						this.ramais.splice(this.ramais.indexOf(ramal), 1);
					});
				});
			}
		},
		created() {
			this.moment = moment;
			axios.get(`/api/usuario/lista-basica`).then(response => {
				this.search.usuarios = response.data;
			});
			axios.get(`/api/setor/lista-basica`).then(response => {
				this.search.setores = response.data;
			});
			this.consultarRamais();
		}
	}
</script>
<style scoped>
	.breadcrumb {
		background-color: #74e4ff;
	}

	.ramais-container {
		background-color: var(--cinza-2);
		margin-bottom: 70px;
		min-height: calc(100vh - 150px);
	}

		.ramais-container input {
			width: 15vw;
			margin-right: 15px;
		}

		.ramais-container select {
			width: 10vw;
			margin-right: 15px;
		}

	.ramais-container-body {
		background-color: #fff;
		height: calc(100% - 45px);
		margin: 10px 15px;
		padding: 0;
		font-size: 12px;
		cursor: default;
		padding: 0 3px;
	}

		.ramais-container-body i.fas {
			cursor: pointer;
		}

	#ramal-ramal {
		padding: 0;
		font-size: 12px;
	}
</style>